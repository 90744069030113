import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../Types/IUser";
import Cookies from 'js-cookie'
import { useGlobalContext } from '../../Hooks/UseGlobalContext';

const PrivateRoute: React.FC<{ user: IUser | null, children: JSX.Element }> = (props) => {
    const { user } = useGlobalContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.roles !== 'admin') {
            return navigate('/home');
        } else if (!user && Cookies.get('_stay') === 'false') {
            Cookies.remove('_stay')
            Cookies.remove('_id')
            return navigate('/login');
        }
    }, [])


    return props.children;
};

export default PrivateRoute;