import CircleLoader from "react-spinners/CircleLoader";
type Props = {
    loading: boolean | undefined
}
export const Spinner: React.FC<Props> = (props) => {
    return (
        <CircleLoader
            color={'#C39BD3'}
            loading={props.loading}
            cssOverride={{ margin: 'auto' }}
            size={45}
            aria-label="Loading Spinner"
            data-testid="loader"
        />
    )
}