import { useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';

type Props = {
    onHide: () => void,
    show: boolean,
    children: React.ReactElement,
    heading: string
}

const ModalCentered: React.FC<Props> = ({ show, onHide, children, heading }) => {

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {heading}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body id="card">
                {/* <Row className="mb-auto mt-5">
                    <Col lg={12} className="mx-auto">
                        <Card>
                            <Card.Body>
                                Much wow
                                <img style={{ borderRadius: '25px' }} src="https://wompampsupport.azureedge.net/fetchimage?siteId=7575&v=2&jpgQuality=100&width=700&url=https%3A%2F%2Fi.kym-cdn.com%2Fentries%2Ficons%2Ffacebook%2F000%2F013%2F564%2Fdoge.jpg" />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> */}
                {/* <h4>Centered Modal</h4>
                <p>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
                </p> */}
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button className="quango-btn mb-3" variant="dark" type="button" onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalCentered;