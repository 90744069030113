import axios from 'axios';
import { API_URL } from '../../Config/config';

export const axiosInstance = axios.create({
    baseURL: API_URL,
    timeout: 60000, // 60 seconds
    // headers: { 'Referer': API_URL },
    responseType: 'json'
});

axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
        // Handle unauthorized error here
        console.log('Unauthorized!');
        window.location.replace('/login')
    }
    return Promise.reject(error);
});
