import { Badge, Card, Col, Row } from 'react-bootstrap';
import { useCallback, useEffect } from "react";
import { AppBadge } from '../Components/Common/AppBadge';

const Help: React.FC = (props) => {

    useEffect(() => {
    })
    return (
        <Row className="mb-auto">

            <Col lg={2} className="ms-auto">
                <div id="tsparticles"></div>
                <Card id="card">
                    <h5 className='quango me-auto'>Index</h5>
                    < hr />
                    <Card.Body>
                        <a href="#">Documentation link</a>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={6} className="me-auto">
                <Card id="card">
                    <h5 className='quango me-auto'>Documentation</h5>
                    <div style={{ display: 'inline-block' }}>
                        <AppBadge color="green" text="Help" />
                    </div>
                    < hr />
                    <Card.Body>
                        <p>Help documentation under construction</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default Help;