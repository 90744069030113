import { Button, Card, Form, ListGroup } from 'react-bootstrap';
import { IDriveFile } from '../../Types/IDriveFile';
import { formatBytes } from '../../Helpers/formatBytes';
import { _deleteDriveFile } from '../../Services/API/drive-api';

type Props = {
    selectedFile: IDriveFile | undefined,
    fetchFiles: () => void,
    handleToast: (message: string, type: string, category: string) => void
}

const FileDelete: React.FC<Props> = (props) => {
    const fileToDelete = props.selectedFile === undefined ? '' : props.selectedFile

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            console.log(props.selectedFile?.id)
            await _deleteDriveFile(props.selectedFile?.id);
            props.fetchFiles();
            props.handleToast('Files', 'File deleted', 'Success')
        } catch (err: any) {
            props.handleToast('Error', err.message, '')
        }
    }

    return (

        <Form onSubmit={handleSubmit} className="mt-3 me   -auto">
            <h5 className='quango'>Delete File</h5>
            <hr></hr>
            {props.selectedFile &&
                <Card className="mb-3 mx-auto">
                    <Card.Header>{props.selectedFile?.name}</Card.Header>
                    <ListGroup variant="flush">
                        <ListGroup.Item>Id: {props.selectedFile?.id.slice(0, 20) + "..."}</ListGroup.Item>
                        <ListGroup.Item>Type: {props.selectedFile?.mimeType}</ListGroup.Item>
                        <ListGroup.Item>Size: {formatBytes(props.selectedFile?.size, 2, true)}</ListGroup.Item>
                    </ListGroup>
                </Card>

            }
            <Button className="quango-btn mb-3" variant="dark" type="submit" disabled={fileToDelete === ''}>
                {fileToDelete === '' ? "No File Selected" : "Delete File"}
            </Button>
        </Form>

    )
};

export default FileDelete;