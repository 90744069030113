import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { API_URL } from '../../Config/config';
import { Button, Card, Form } from 'react-bootstrap';


interface IFile {
    file_id: number;
    name: string;
    fileSizeInMegabytes: number;
    mimeType: string;
    extension: string;
    disabled: boolean;
}

type Props = {
    selectedFile: IFile | null,
    fetchFiles: () => void,
    handleToast: (heading: string, message: string, type: string) => void
}

const FileRename: React.FC<Props> = (props) => {
    const [newName, setNewName] = useState('');
    const fileToRename = props.selectedFile === null ? '' : props.selectedFile.name + props.selectedFile.extension

    const handleRename = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await axios.patch(`${API_URL}/files/`,
                {
                    file: `${fileToRename}`,
                    name: newName, extension: props.selectedFile?.extension
                }, {
                withCredentials: true,
                headers: { 'Content-type': 'application/json; charset=UTF-8' }
            });
        } catch (error) {
            console.log(error)
        }
        setNewName('')
        props.fetchFiles();
        props.handleToast('Files', 'File renamed', 'Success')
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewName(e.target.value)
    }

    return (
        <Form onSubmit={handleRename} style={{ width: '50%' }} className="mt-3 me-auto">
            <Form.Group className="mb-3" controlId="formBasicFile">
                {/* <Form.Label>Old</Form.Label> */}
                <Form.Control value={props.selectedFile?.name} disabled type="text" name="selected-file" placeholder="Select File" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
                {/* <Form.Label>New</Form.Label> */}
                <Form.Control value={newName} type="text" name="new" placeholder="Enter Name" autoComplete='current-password' onChange={handleChange} />
                <Form.Control.Feedback type="invalid">

                </Form.Control.Feedback>
            </Form.Group>

            <Button className="quango-btn mb-3" variant="dark" type="submit" disabled={fileToRename === '' || newName.length < 1}>
                {fileToRename === '' ? 'No File Selected' : newName.length < 1 ? 'Enter Name' : 'Rename File'}
            </Button>
        </Form>
    )
};

export default FileRename;