import { useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

const UseLocationExample: React.FC = () => {
    const location = useLocation();
    return (
        <>
            <div>This is the about component for {location.state ? location.state.name : `No User`}</div>
            <Button variant="primary" type="submit">
                Submit
            </Button>
        </>
    )
}

export default UseLocationExample