import Container from 'react-bootstrap/Container';
import { Outlet } from 'react-router-dom';
import { AppBreadcrumb } from './Components/Layout/Breadcrumb';
import Footer from './Components/Layout/Footer';
import Header from './Components/Layout/Header';
import { IUser } from './Types/IUser';

type Props = {
    user: IUser | null
    handleToast: (heading: string, message: string, type: string) => void
}

const AppContainer: React.FC<Props> = (props) => {
    const showFooter = window.location.pathname.includes('home')
    return (
        <>
            <Header handleToast={props.handleToast} user={props.user} />
            <Container className="d-flex flex-column justify-content-center mb-5" fluid>
                <AppBreadcrumb />
                <Outlet />
            </Container>
            {showFooter &&
                <Footer user={props.user} />
            }
        </>
    );
}
// 
export default AppContainer;