import { createContext, useContext } from "react"
import { IUser } from "../Types/IUser"

export type ContextState = {
    user: IUser | null
    setUser: (user: IUser | null) => void
}

export const MyGlobalContext = createContext<ContextState>({
    user: null,
    setUser: () => { },
})

export const useGlobalContext = () => useContext(MyGlobalContext)
