import { ICreateUser } from '../../Types/ICreateUser';
import { IUpdateUser } from '../../Types/IUpdateUser';
import { axiosInstance } from '../Config/Axios';

const _createUser = async (data: ICreateUser) => {
    return await axiosInstance({
        method: 'post',
        url: '/admin/users/',
        withCredentials: true,
        data
    })
}

const _getUser = async (user_id: number | string | undefined) => {
    return await axiosInstance({
        method: 'get',
        url: `/users/${user_id}`,
        withCredentials: true,
    })
}

const _updateUser = async (user_id: number | undefined, data: IUpdateUser) => {
    return await axiosInstance({
        method: 'patch',
        url: `/users/${user_id}`,
        withCredentials: true,
        data
    })
}

export { _createUser, _updateUser, _getUser }