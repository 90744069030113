import { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { JsxElement } from 'typescript';
import { AppBadge } from './AppBadge';
import SpringAnimation from './SpringAnimation';

type Props = {
    heading?: string,
    children: React.ReactElement,
    icons?: string[],
}

const ContentWrapper: React.FC<Props> = ({ heading, children, icons }) => {

    return (
        <SpringAnimation animation="fadein">
            <Card>
                <Card.Body id="card">
                    <>
                        <h5 className='quango me-auto'>{heading}</h5>
                        < hr />
                        {icons?.map((icon) =>
                            <AppBadge key={icon} text={icon} style={icons.length > 1 ? { marginRight: '3px' } : {}}></AppBadge>
                        )}
                        {children}
                    </>
                </Card.Body>
            </Card>
        </SpringAnimation>
    );
}

export default ContentWrapper;