
import React, { useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import { _uploadDriveFile } from '../../Services/API/drive-api';

type Props = {
    fetchFiles: () => void,
    handleToast: (heading: string, message: string, type: string) => void
}

type HTMLInputElementFile = {
    lastModified: number
    name: string
    size: number
    type: string
    webkitRelativePath: string
}

const FileUpload: React.FC<Props> = (props) => {
    const [selectedFile, setSelectedFile] = useState<HTMLInputElementFile | null>();
    const fileUploader = useRef<HTMLInputElement>(null)

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData();
        //@ts-ignore
        formData.append("file", selectedFile);
        try {
            await _uploadDriveFile(formData)
            setSelectedFile(null)
            if (fileUploader.current) fileUploader.current.value = ''
            props.fetchFiles();
            props.handleToast('Files', 'File uploaded', 'Success')
        } catch (error) {
            console.log(error)
        }
    }

    const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedFile(event.target.files[0])
        }
    }

    return (
        <Form onSubmit={handleSubmit} style={{ width: '50%' }} className="mt-3 me-auto">
            <h5 className='quango'>Upload File</h5>
            <hr></hr>
            <Form.Group controlId="formFile" className="mb-4">
                <Form.Control ref={fileUploader} onChange={handleFileSelect} type="file" autoComplete='on' />
            </Form.Group>

            <Button className="quango-btn mb-3" variant="dark" type="submit" disabled={selectedFile === null}>
                {selectedFile === null ? 'Choose File' : 'Upload File'}
            </Button>
        </Form>
    )
};

export default FileUpload;