import { Toast, ToastContainer } from 'react-bootstrap';
import { IAppToastComponent } from '../Types/IAppToastComponent'

const AppToast: React.FC<IAppToastComponent> = (props) => {
    const isLogin = window.location.pathname.includes('/login')
    return (
        <ToastContainer position="top-end" className='me-3' id={isLogin ? 'app-toast-login' : 'app-toast'}>
            <Toast bg={props.type ? props.type : ''} onClose={() => props.setShowToast(false)} show={props.showToast} delay={3000} autohide>
                <Toast.Header>
                    <img
                        src="holder.js/20x20?text=%20"
                        className="rounded me-2"
                        alt=""
                    />
                    <strong className="me-auto">{props.heading}</strong>
                    {/* <small>{props.type}</small> */}
                </Toast.Header>
                <Toast.Body>{props.message}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
}

export default AppToast;