export function formatBytes(bytes: string | number, decimals: number, withUnit: boolean): number | string {
    if (!+bytes) return 0

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(+bytes) / Math.log(k))
    if (withUnit === false) {
        return parseFloat((+bytes / Math.pow(k, i)).toFixed(dm))
    } else {
        return `${parseFloat((+bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
}