import { Form, Table, Card, Badge, Dropdown } from 'react-bootstrap';
import { IDriveFile } from '../../Types/IDriveFile'
import moment from 'moment';
import SpringAnimation from '../Common/SpringAnimation'
import { AppBadge } from '../Common/AppBadge';

type Props = {
    handleFileSelectCheckbox: (e: any) => void,
    files: IDriveFile[] | []
}

export const TemplateManagementTable: React.FC<Props> = (props) => {
    return (
        <SpringAnimation animation="fadein">
            <Card id="card">
                <Card.Body >
                    <h5 className='quango'>Files</h5>
                    <AppBadge color='dark' text='Google Drive' overlayTriggerText='Manage your templates on Google Drive' overlayTriggerPlacement='bottom' />
                    <hr></hr>
                    <Table bordered hover responsive variant="secondary" size="">
                        <thead>
                            <tr>
                                <th>{props.files.find((f) => f.selected) ? 'Selected' : 'Select'}</th>
                                <th>#</th>
                                <th>Name</th>
                                <th>Kind</th>
                                <th>Created</th>
                                <th>Mimetype</th>
                                <th>Size</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.files.map((file: IDriveFile, index) => {
                                return (
                                    <tr key={index}>
                                        <td>

                                            <Form.Check
                                                type={`checkbox`}
                                                label={file.selected ? `${file.name.length > 20 ? file.name.slice(0, 20) + "..." : file.name}` : ``}
                                                id={String(file.id)}
                                                onChange={props.handleFileSelectCheckbox}
                                                disabled={file.disabled}
                                                checked={file.selected}
                                            />
                                        </td>
                                        <td>{file.id.slice(0, 20) + "..."}</td>
                                        <td>{file.name}</td>
                                        <td>{file.kind}</td>
                                        <td>{moment(file.createdTime).format('LL')}</td>
                                        <td>{file.mimeType} <img src={file.iconLink} height="13"></img></td>
                                        <td>{Boolean(file.size) ? (+file.size / (1024 * 1024)).toFixed(3) + ' MB' : null}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </SpringAnimation>
    );
}
