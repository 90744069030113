import { Dispatch, SetStateAction } from 'react';
import { API_URL } from '../../Config/config';
import { Button, Card, Form, ListGroup } from 'react-bootstrap';

interface IFile {
    file_id: number;
    name: string;
    fileSizeInMegabytes: number;
    mimeType: string;
    extension: string;
    disabled: boolean;
    selected: boolean;
}

type Props = {
    selectedFile: IFile | null,
    handleToast: (heading: string, message: string, type: string) => void,
    setSelectedFile: Dispatch<SetStateAction<IFile | null>>,
    resetCheckBoxes: () => void
}

const FileDownload: React.FC<Props> = (props) => {
    const fileToDownload = props.selectedFile === null ? '' : props.selectedFile.name + props.selectedFile.extension

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        var link: HTMLAnchorElement = document.createElement('a')
        link.href = `${API_URL}/files/${fileToDownload}`
        document.body.appendChild(link)
        link.click();
        props.setSelectedFile(null)
        props.resetCheckBoxes();
        props.handleToast('Files', 'File downloaded', 'Success')
    }

    return (
        <Form onSubmit={handleSubmit} style={{ width: '50%' }} className="mt-3 me-auto">
            {props.selectedFile &&
                <Card className="mb-3 mx-auto">
                    <Card.Header>{props.selectedFile?.name}{props.selectedFile?.extension}</Card.Header>
                    <ListGroup variant="flush">
                        <ListGroup.Item>Id: {props.selectedFile?.file_id}</ListGroup.Item>
                        <ListGroup.Item>Type: {props.selectedFile?.mimeType}</ListGroup.Item>
                        <ListGroup.Item>Size: {props.selectedFile?.fileSizeInMegabytes.toFixed(3) + " MB"}</ListGroup.Item>
                    </ListGroup>
                </Card>
            }
            <Button className="quango-btn mb-3" variant="dark" type="submit" disabled={fileToDownload === ''}>
                {fileToDownload === '' ? 'No File Selected' : 'Download File'}
            </Button>
        </Form>
    )
};

export default FileDownload;