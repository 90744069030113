import React, { useCallback } from 'react'
import Particles from 'react-particles'
import { loadFull } from 'tsparticles'
import { particlesOptionsLogin } from '../../Options/particlesOptionsLogin'

const TsParticles = () => {

    const particlesInit = useCallback(async (engine: any) => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine)
    }, [])

    const particlesLoaded = useCallback(async (container: any) => {
        // await console.log(container);
    }, [])
    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            //@ts-ignore
            options={particlesOptionsLogin}
            canvasClassName="particles-canvas more stronger"
            className="particles"
        />
    )
}

export default TsParticles