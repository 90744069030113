import { Nav, Row } from 'react-bootstrap';

const Tabs: React.FC<{ handleTabSelect: (e: any) => void, activeKey: string, tabs: string[] }> = (props) => {

    return (
        <Nav variant="tabs" id="tabs-nav" defaultActiveKey="1" onSelect={props.handleTabSelect} activeKey={props.activeKey}>
            {props.tabs.map((t, index) => {
                return (
                    <Nav.Item key={index}>
                        <Nav.Link id="tab-link" eventKey={t}>{t}</Nav.Link>
                    </Nav.Item>
                )
            })}
        </Nav>
    );
}

export default Tabs;