import { Badge, Popover } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

type Props = {
    color?: string,
    text: string,
    overlayTriggerText?: string,
    overlayTriggerPlacement?: 'right' | 'bottom',
    style?: React.CSSProperties
}

export const AppBadge: React.FC<Props> = ({ color, text, overlayTriggerText, overlayTriggerPlacement }) => {
    const popover = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Files</Popover.Header> */}
            <Popover.Body>
                <i>{overlayTriggerText}</i>
            </Popover.Body>
        </Popover>
    )

    let colorClass = undefined;
    switch (color) {
        case 'green': {
            colorClass = 'badge-green'
            break;
        }
        case 'blue': {
            colorClass = 'badge-blue'
            break;
        }
        default: {
            colorClass = 'badge'
        }
    }

    if (overlayTriggerText) {
        return (
            <div style={{ display: 'inline-block' }}>
                <OverlayTrigger trigger={["hover", "focus"]} placement={overlayTriggerPlacement} overlay={popover}>
                    <Badge bg="dark" id={colorClass}>{text}</Badge>
                </OverlayTrigger >
            </div>

        )
    } else {
        return (
            <div style={{ display: 'inline-block' }}>
                <Badge bg="dark" id={colorClass}>{text}</Badge>
            </div>
        )
    }
}