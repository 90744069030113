import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../Hooks/UseGlobalContext';
import Cookies from 'js-cookie'
import { API_URL } from '../../Config/config';
import { Card } from 'react-bootstrap';
import { IHandleToast } from '../../Types/IHandleToast'
import SpringAnimation from '../../Components/Common/SpringAnimation';
import { _createUser } from '../../Services/API/user-api';


const CreateUser: React.FC<IHandleToast> = (props) => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [roles, setRoles] = useState('user')
    //checkbox
    const [isAdmin, setIsAdmin] = useState(false)

    const navigate = useNavigate()
    const { setUser } = useGlobalContext();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch (e.target.name) {
            case 'firstName': {
                setFirstName(e.target.value)
                break;
            }
            case 'lastName': {
                setLastName(e.target.value)
                break;
            }
            case 'email': {
                setEmail(e.target.value)
                break;
            }
            case 'password': {
                setPassword(e.target.value)
                break;
            }
        }
    }

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsAdmin((isAdmin) => !isAdmin)
        if (e.target.checked) {
            setRoles('admin')
        } else {
            setRoles('user')
        }

    }

    const handleCreateUser = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault()
            await _createUser({ first_name: firstName, last_name: lastName, email, password, roles })
            navigate('/home', {
                state: {
                    message: `New account created`
                }
            })
            props.handleToast('Accounts', 'Account Created', 'Success')
        } catch (err) {
            console.log('use this err', err)
            return
        }
    }

    return (
        <Row className="mb-auto mt-5">

            <Col lg={5} className="mx-auto">
                <SpringAnimation animation="fadein">
                    <Card id="card">
                        <Card.Body>
                            <h5 className='quango'>Create Account</h5>
                            <hr></hr>
                            <Form onSubmit={handleCreateUser} validated={false}>
                                <Form.Group className="mb-3" controlId="formBasicFirstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control onChange={handleChange} type="text" name="firstName" placeholder="Enter firstname" autoComplete='email' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicLastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control onChange={handleChange} type="text" name="lastName" placeholder="Enter lastname" autoComplete='lastname' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control onChange={handleChange} type="email" name="email" placeholder="Enter email" autoComplete='email' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control onChange={handleChange} type="password" name="password" placeholder="Enter password" autoComplete='current-password' />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check checked={isAdmin} onChange={handleCheck} type="checkbox" label="Admin" />
                                </Form.Group>
                                {/* <div className="d-grid gap-2"></div> */}
                                {/* <div className='d-flex align-items-center justify-content-center'></div> */}
                                <Button variant="dark" type="submit" className="mt-1">
                                    Create User
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </SpringAnimation>
            </Col>
        </Row>
    );

}
export default CreateUser;