import { ILogin } from 'src/Types/ILogin';
import { axiosInstance } from '../Config/Axios';

const _login = async (data: ILogin) => {
    return await axiosInstance({
        method: 'post',
        url: '/auth/login',
        withCredentials: true,
        data
    })
}

export { _login }
