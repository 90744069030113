import type { ChartData, ChartOptions } from 'chart.js';

const pieData: ChartData<'pie'> = {
    labels: [`GB Usage`, `GB Limit`],
    datasets: [
        {
            label: `Storage usage (GB)`,

            data: [],
            backgroundColor: [
                'rgba(195,155,211, 0.2)',
                'rgba(171,211,155, 0.2)',
            ],
            borderColor: [
                'rgba(195,155,211, 1)',
                'rgba(171,211,155, 1)',
            ],
            borderWidth: 1,
        },
    ]
}

const pieOptions: ChartOptions<'pie'> = {
    responsive: true,
    plugins: {
        legend: {
            position: "bottom",
        },
        title: {
            display: true,
            text: ``,
        }
    },
}

export { pieData, pieOptions }
