import { getDaysInMonth } from '../../Helpers/getDaysInMonth'
import moment from 'moment'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Spinner } from '../../Components/Common/Spinner'
import { AppBadge } from '../Common/AppBadge';
import SpringAnimation from '../Common/SpringAnimation';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ReportsLineChart: React.FC = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState<number[]>([]);

    const getReportsData = async () => {
        setLoading(true)
        setTimeout(() => {
            setData([0, 0, 0, 1, 0, 0, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 4, 0, 0])
            setLoading(false)
        }, 1350)

    }

    useEffect(() => {
        getReportsData()
    }, [])

    const options = {
        responsive: true,
        plugins: {
            // legend: {
            //     position: 'top' as const,
            // },
            title: {
                display: true,
                text: `Reporting ${moment().format('MMMM')} ${moment().format('YYYY')}`,
            },

        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: "Count",
                    align: "center",
                    color: "black",
                    padding: 4,

                },
                ticks: {
                    callback: function (value: string, index: number, ticks: number) {
                        return value
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: "Days",
                    align: "center",
                    color: "black",
                    padding: 4,
                }
            }
        }
    };

    const daysInMonth = getDaysInMonth()
    let labels = [];
    // const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    for (let i = 0; i <= daysInMonth; i++) {
        // labels.push(`${i} ${moment().format("MMM")}`)
        labels.push(`${i}`)
    }

    const reportingLineData = {
        labels,
        datasets: [
            // {
            //     label: 'Dataset 1',
            //     data: days,
            //     borderColor: 'rgb(255, 99, 132)',
            //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
            // },
            {
                label: 'Number of reports',
                data: data,
                // data: [{ x: <b>test</b>, y: 20 }, { x: '2016-12-26', y: 10 }],
                borderColor: 'rgba(52,162,235, 1)',
                backgroundColor: 'rgba(52,162,235, 0.2)',
                borderWidth: 1,
            },


        ],
    };

    return (<>
        {loading ?
            <Spinner loading={loading} />
            :
            <SpringAnimation animation="fadein">
                <Card id="card">
                    <Card.Body>
                        <h5 className='quango me-auto'>Reports over 30 days</h5>
                        <AppBadge color={'blue'} text={"Analytics"} />
                        < hr />
                        {/* @ts-ignore */}
                        <Line options={options} data={reportingLineData} />
                    </Card.Body>
                </Card>
            </SpringAnimation>
        }
    </>

    )
}

export default ReportsLineChart