import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

export const AppBreadcrumb = () => {

    const path = window.location.pathname
    return (
        <Row className="mt-4 mb-3">
            <Col lg={8} className="mx-auto">
                <Breadcrumb>
                    <NavLink id="breadcrumb" className="nav-link quango" to="/home">Home </NavLink>
                    {path.includes('reporting') &&
                        <>
                            <h3 style={{ margin: '0 5px 0 5px' }}> / </h3>
                            <NavLink id="breadcrumb" className="nav-link quango" to="/reporting">Reporting</NavLink>
                        </>
                    }
                    {path.includes('users') &&
                        <>
                            <h3 style={{ margin: '0 5px 0 5px' }}> / </h3>
                            <NavLink id="breadcrumb" className="nav-link quango" to="/admin/users">Users</NavLink>
                        </>
                    }
                    {path.includes('profile') &&
                        <>
                            <h3 style={{ margin: '0 5px 0 5px' }}> / </h3>
                            <NavLink id="breadcrumb" className="nav-link quango" to="/profile">Profile</NavLink>
                        </>
                    }
                    {path.includes('templates') &&
                        <>
                            <h3 style={{ margin: '0 5px 0 5px' }}> / </h3>
                            <NavLink id="breadcrumb" className="nav-link quango" to="/files/templates">Templates</NavLink>
                        </>
                    }
                    {path.includes('help') &&
                        <>
                            <h3 style={{ margin: '0 5px 0 5px' }}> / </h3>
                            <NavLink id="breadcrumb" className="nav-link quango" to="/help">Help</NavLink>
                        </>
                    }
                    {path.includes('reporttype') &&
                        <>
                            <h3 style={{ margin: '0 5px 0 5px' }}> / </h3>
                            <NavLink id="breadcrumb" className="nav-link quango" to="/reporttype">Report Type</NavLink>
                        </>
                    }
                </Breadcrumb>
            </Col>
        </Row>
    );
}

