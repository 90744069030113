import { axiosInstance } from '../Config/Axios';

const _createReport = async (data: any) => {
    return await axiosInstance({
        method: 'post',
        url: '/reporting/',
        withCredentials: true,
        data: data
    })
}

export { _createReport }