import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Nav, Navbar, Row } from 'react-bootstrap';
import { Link, Outlet, NavLink } from 'react-router-dom';
import SpringAnimation from '../Common/SpringAnimation';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { particlesOptionsFooter } from '../../Options/particlesOptionsFooter';
import { IUser } from '../../Types/IUser';

type Props = {
    user: IUser | null
}

const Footer: React.FC<Props> = (props) => {
    const particlesInit = useCallback(async (engine: any) => {
        // console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: any) => {
        // await console.log(container);
        let el = (document.querySelector(".particles-footer") as HTMLCanvasElement);
        // el.style.top = '850px'
        // el.style.left = '20%'
        el.style.height = 'auto'
        el.style.position = 'absolute'
        el.style.background = 'none'
    }, []);

    const [scroll, setScroll] = useState({ pageYOffset: 0 });

    document.addEventListener('scroll', () => {
        setScroll({ pageYOffset: window.pageYOffset });
    })

    useEffect(() => {
        if (scroll.pageYOffset > 50) {
            document.getElementById('footer')!.classList.add("hidden");
        }
        else {
            document.getElementById('footer')!.classList.remove("hidden");
            document.getElementById('footer')!.classList.add("visible");
        }
    }, [scroll.pageYOffset])

    return (

        <Row>
            <SpringAnimation animation='fadein'>
                <Navbar expand="lg" bg="dark" variant="dark" fixed="bottom" id="footer">
                    {props.user && props.user.user_settings && props.user.user_settings.show_particles &&
                        <Particles
                            id="tsparticles"
                            init={particlesInit}
                            loaded={particlesLoaded}
                            //@ts-ignore
                            options={particlesOptionsFooter}
                            canvasClassName="particles-footer"
                            className='particles'
                        />
                    }
                    <Container>
                        <Nav variant="dark" className="me-auto">
                            <NavLink className="nav-link quango" to="/help">Help</NavLink>
                        </Nav>
                    </Container>
                </Navbar>
            </SpringAnimation>
        </Row>
    );
}

export default Footer;