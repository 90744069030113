import { useEffect, useState } from "react"
import { _getStorageQuota } from '../../Services/API/drive-api'
import { formatBytes } from '../../Helpers/formatBytes'
import { Card, Col, Row, ListGroup, Badge } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import type { ChartData, ChartOptions } from 'chart.js';
import SpringAnimation from '../../Components/Common/SpringAnimation'
import { Spinner } from '../../Components/Common/Spinner'
import { AppBadge } from '../Common/AppBadge';
import { pieData, pieOptions } from './ChartData/StorageQuotaPieChartData';
import ContentWrapper from '../Common/ContentWrapper';

ChartJS.register(ArcElement, Legend);

const StorageQuotaPieChart: React.FC = () => {
    const [storageQuotaPieData, setStorageQuotaPieData] = useState<ChartData<'pie'>>(pieData)
    const [options, setOptions] = useState(pieOptions)
    const [loading, setLoading] = useState(true)

    const setPieData = async () => {
        const response = await _getStorageQuota()
        setStorageQuotaPieData({
            ...storageQuotaPieData,
            datasets: [
                {
                    ...storageQuotaPieData.datasets[0],
                    data: [response.data.storageQuota?.usage, response.data.storageQuota?.limit],
                },
            ]
        })
        setOptions({
            ...options,
            responsive: true,
            plugins: {
                ...options.plugins,
                title: {
                    display: true,
                    text: `Used ${formatBytes(response.data.storageQuota.usage, 2, true)} of ${formatBytes(response.data.storageQuota.limit, 2, true)}`,
                }
            }
        })
    }

    const getDriveAbout = async () => {
        setLoading(true)
        await setPieData()
        setLoading(false)
    }

    useEffect(() => {
        getDriveAbout()
    }, [])

    return (
        <>
            {loading ?
                <Spinner loading={loading} /> :
                <ContentWrapper heading='Storage Quota' icons={['Google Drive']}>
                    <Pie className='' options={options} data={storageQuotaPieData} // width={850} // height={850}
                    />
                </ContentWrapper>
            }
        </>
    )
}

export default StorageQuotaPieChart
