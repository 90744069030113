import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../Types/IUser";
import Cookies from 'js-cookie'
import { useGlobalContext } from '../../Hooks/UseGlobalContext';

const ProtectedRoute: React.FC<{ user: IUser | null, children: JSX.Element }> = (props) => {
    const { user } = useGlobalContext();

    const navigate = useNavigate();
    useEffect(() => {
        if (!user && !Cookies.get('_id')) {
            Cookies.remove('_id')
            Cookies.remove('_stay')
            return navigate('/login');
        } else if (!user && Cookies.get('_stay') === 'false') {
            Cookies.remove('_id')
            Cookies.remove('_stay')
            return navigate('/login');
        }
    }, [])

    return props.children;
};

export default ProtectedRoute;