import axios from 'axios';
import { API_URL } from '../../Config/config';
import { Button, Card, Form, ListGroup } from 'react-bootstrap';


interface IFile {
    file_id: number;
    name: string;
    fileSizeInMegabytes: number;
    mimeType: string;
    extension: string;
    disabled: boolean;
    selected: boolean;
}

type Props = {
    selectedFile: IFile | null,
    fetchFiles: () => void,
    handleToast: (message: string, type: string, category: string) => void
}

const FileDelete: React.FC<Props> = (props) => {
    const fileToDelete = props.selectedFile === null ? '' : props.selectedFile.name + props.selectedFile.extension


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await axios.delete(`${API_URL}/files/${fileToDelete}`, { withCredentials: true });
            props.fetchFiles();
            props.handleToast('Files', 'File deleted', 'Success')
        } catch (err: any) {
            props.handleToast('Error', '', err.message)
        }
    }

    return (

        <Form onSubmit={handleSubmit} style={{ width: '50%' }} className="mt-3 me   -auto">
            {props.selectedFile &&

                <Card className="mb-3 mx-auto">
                    <Card.Header>{props.selectedFile?.name}{props.selectedFile?.extension}</Card.Header>
                    <ListGroup variant="flush">
                        <ListGroup.Item>Id: {props.selectedFile?.file_id}</ListGroup.Item>
                        <ListGroup.Item>Type: {props.selectedFile?.mimeType}</ListGroup.Item>
                        <ListGroup.Item>Size: {props.selectedFile?.fileSizeInMegabytes.toFixed(3) + " MB"}</ListGroup.Item>
                    </ListGroup>
                </Card>

            }

            <Button className="quango-btn mb-3" variant="dark" type="submit" disabled={fileToDelete === ''}>
                {fileToDelete === '' ? "No File Selected" : "Delete File"}
            </Button>
        </Form>

    )
};

export default FileDelete;