import { Form, Table } from 'react-bootstrap';
import { useSpring, animated } from 'react-spring'
import { IFile } from '../../Types/IFile'

type Props = {
    handleFileSelectCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void,
    files: IFile[]
}

const FileManagementTable: React.FC<Props> = (props) => {
    const springProps = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, config: { delay: 750, duration: 750 } })


    return (
        <animated.div style={springProps}>

            <Table striped bordered responsive variant="dark" size="sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Extension</th>
                        <th>Mimetype</th>
                        <th>Size</th>
                        <th>{props.files.find((f) => f.selected) ? 'Selected' : 'Select'}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.files.map((file: IFile, index) => {
                        return (
                            <tr key={index}>
                                <td>{file.file_id}</td>
                                <td>{file.name}</td>
                                <td>{file.extension}</td>
                                <td>{file.mimeType}</td>
                                <td>{file.fileSizeInMegabytes.toFixed(3) + ' MB'} </td>
                                <td>
                                    <div key={`default-checkbox`} className="">
                                        <Form.Check
                                            type={`checkbox`}
                                            label={file.selected ? `${file.name.length > 20 ? file.name.slice(0, 20) + "..." : file.name}` : ``}
                                            id={String(file.file_id)}
                                            onChange={props.handleFileSelectCheckbox}
                                            disabled={file.disabled}
                                            checked={file.selected}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </animated.div>
    );
}

export default FileManagementTable;