import { useEffect, useState } from 'react'
import { _getPlatform } from '../../Services/API/platform'
import { Card, Col, Row, ListGroup, Badge } from 'react-bootstrap';
import { Spinner } from '../../Components/Common/Spinner'
import SpringAnimation from '../../Components/Common/SpringAnimation'
import ReactPlayer from 'react-player/youtube'

interface IYouTubeCard {

}

const YouTubeCard: React.FC = () => {
    const [platform, setPlatform] = useState<IYouTubeCard | null>(null)
    const [loadingPlatform, setLoadingPlatform] = useState(true)

    const getPlatformInfo = async () => {
        setLoadingPlatform(true)
        setLoadingPlatform(false)
    }

    useEffect(() => {
        getPlatformInfo()
    }, [])

    return (
        <>
            {loadingPlatform ?
                <Spinner loading={loadingPlatform} /> :
                <SpringAnimation animation="fadein" >
                    <Card id="card">
                        <Card.Body>
                            <h5 className='quango me-auto'>Media</h5>
                            < hr />
                            <ReactPlayer width="auto" url={'https://www.youtube.com/watch?v=KQE29az48gM&ab_channel=ThePsychedelicMuse'} />
                        </Card.Body>
                    </Card>
                </SpringAnimation>
            }
        </>
    )
}

export default YouTubeCard
