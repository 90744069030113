import { IUser } from "./Types/IUser";
import { ReactNode, useState } from 'react';
import { MyGlobalContext } from './Hooks/UseGlobalContext'
import { BrowserRouter as Router } from 'react-router-dom';

type Props = {
    children: ReactNode
}

const AppWrapper: React.FC<Props> = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(null)

    return (
        <MyGlobalContext.Provider value={{ user, setUser }}>
            <Router>
                {children}
            </Router>
        </MyGlobalContext.Provider>
    )
}

export default AppWrapper;