import axios from 'axios';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { API_URL } from '../../Config/config';
import { IUser } from '../../Types/IUser';
import TablePagination from '../../Components/TablePagination';
import CircleLoader from "react-spinners/CircleLoader";
import { Spinner } from '../../Components/Common/Spinner';
import moment from 'moment';
import SpringAnimation from '../../Components/Common/SpringAnimation';

const Users: React.FC = (props) => {
    const [users, setUsers] = useState<IUser[] | []>([]);
    const [loading, setLoading] = useState(true)

    const fetchUsers = async () => {
        setLoading(true)
        const response = await axios.get(`${API_URL}/users`, { withCredentials: true })
        setUsers(response.data)
        setTimeout(() => {
            setLoading(false)
        }, 500)
    }

    useEffect(() => {
        fetchUsers()
    }, [])

    return (
        <Row className="mb-auto">
            {loading ?
                <Spinner
                    loading={loading}
                />
                :
                <Col lg={8} className={"mx-auto"}>
                    <SpringAnimation animation='fadein'>
                        <Card id="card">
                            <Card.Body >
                                <h5 className='quango'>Users</h5>
                                <hr></hr>
                                <Table striped responsive bordered hover variant="secondary">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Created At</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Email</th>
                                            <th>Verified</th>
                                            <th>Logged In</th>
                                            <th>Last Logged In</th>
                                            <th>Roles</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{user.user_id}</td>
                                                    <td>{moment(user.created_at).format('ll')}</td>
                                                    <td>{user.first_name}</td>
                                                    <td>{user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    <td>{user.verified}</td>
                                                    <td>{user.logged_in}</td>
                                                    <td>{user.last_logged_in}</td>
                                                    <td>{user.roles}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                                {/* <PaginationForTable /> */}
                            </Card.Body>
                        </Card>
                    </SpringAnimation>

                </Col>
            }
        </Row>
    );

}

export default Users;