import { useSpring, animated } from 'react-spring'

type Props = {
    children: JSX.Element,
    animation: string
};

const SpringAnimation: React.FC<Props> = ({ children, animation }) => {
    let properties = { to: {}, from: {}, config: { delay: 750, duration: 750 } }
    if (animation = 'fadeout') {
        properties = { to: { opacity: 1 }, from: { opacity: 0 }, config: { delay: 750, duration: 750 } }
    } else if (animation = 'fadein') {
        properties = { to: { opacity: 0 }, from: { opacity: 1 }, config: { delay: 750, duration: 750 } }
    }
    const springProps = useSpring(properties)

    return (
        <>
            <animated.div className="spring-100" style={springProps}>
                {children}
            </animated.div>
        </>
    )
}

export default SpringAnimation
