import { axiosInstance } from '../Config/Axios';

const _getDriveUser = async () => {
    return await axiosInstance({
        method: 'get',
        url: '/drive/storage-quota/',
        withCredentials: true
    })
}

const _getStorageQuota = async () => {
    return await axiosInstance({
        method: 'get',
        url: '/drive/storage-quota/',
        withCredentials: true
    })
}

const _getDriveFiles = async () => {
    return await axiosInstance({
        method: 'get',
        url: '/drive/',
        withCredentials: true
    })
}

const _deleteDriveFile = async (id: string | undefined) => {
    return await axiosInstance({
        method: 'delete',
        url: `/drive/${id}`,
        withCredentials: true,
    })
}

const _uploadDriveFile = async (formData: FormData) => {
    return await axiosInstance({
        method: 'post',
        url: `/drive/`,
        data: formData,
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" }
    })
}

export { _getStorageQuota, _getDriveFiles, _deleteDriveFile, _uploadDriveFile, _getDriveUser }