import { Button, Card, Col, Row } from 'react-bootstrap';
import { IHandleToast } from '../../Types/IHandleToast';
import { GiBiohazard } from 'react-icons/gi'
import { SiMicrosoftexcel } from 'react-icons/si'

const ReportTypeSelection: React.FC<IHandleToast> = (props) => {

    return (
        <Row className="mb-auto">
            <Col lg={8} className="mx-auto d-flex flex-row mb-3 justify-content-center">
                <Card style={{ width: '18rem' }} className="mx-3">
                    {/* <Card.Img variant="top" style={{ padding: '25px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKiQuwMVTVwOSUN8hw0Zclw8qp4KSnJqJL8w&usqp=CAU" /> */}

                    <GiBiohazard size="225" className='mx-auto' />
                    <Card.Body>
                        <Card.Title className="quongo">Asbestos</Card.Title>
                        <Card.Text>
                            {/* Some quick example text to build on the card title and make up the
                            bulk of the card's content. */}
                        </Card.Text>
                        <div className="d-grid gap-2">

                            <Button variant="dark">Select</Button>
                        </div>
                    </Card.Body>
                </Card>
                <Card style={{ width: '18rem' }} className="mx-3">
                    {/* <Card.Img variant="top" style={{ padding: '25px' }} src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKiQuwMVTVwOSUN8hw0Zclw8qp4KSnJqJL8w&usqp=CAU" /> */}
                    <SiMicrosoftexcel size="225" className='mx-auto' />
                    <Card.Body>
                        <Card.Title className="quongo">Excel</Card.Title>
                        <Card.Text>
                            {/* Some quick example text to build on the card title and make up the
                            bulk of the card's content. */}
                        </Card.Text>
                        <div className="d-grid gap-2">

                            <Button variant="dark">Select</Button>
                        </div>
                    </Card.Body>
                </Card>

            </Col>
        </Row>
    );
}

export default ReportTypeSelection;