import { CSSProperties, useEffect, useState } from "react"
import { Card, Col, Form, Row } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from '../../Config/config';
import FileUpload from '../../Components/FileManagement/FileUpload';
import Tabs from '../../Components/Common/Tabs';
import FileManagementTable from '../../Components/FileManagement/FileManagementTable'
import FileDownload from '../../Components/FileManagement/FileDownload';
import { IHandleToast } from '../../Types/IHandleToast';
import FileRename from '../../Components/FileManagement/FileRename';
import FileDelete from '../../Components/FileManagement/FileDelete';
import { Spinner } from '../../Components/Common/Spinner'
import { IFile } from '../../Types/IFile';

const FileManagement: React.FC<IHandleToast> = (props) => {
    const [activeKey, setActiveKey] = useState("Upload")
    const [loadingFilesTable, setLoadingFilesTable] = useState(true)
    const [loadingIframe, setLoadingIframe] = useState(true)
    const [files, setFiles] = useState<IFile[] | []>([])
    const [selectedFile, setSelectedFile] = useState<IFile | null>(null);

    const handleTabSelect = (eventKey: string) => setActiveKey(eventKey);

    const fetchFiles = async () => {
        setLoadingFilesTable(true)
        const response = await axios.get(`${API_URL}/files`, { withCredentials: true })
        setSelectedFile(null)
        const updated = response.data.map((f: IFile) => {
            f.disabled = false
            f.selected = false
            return f
        })
        setFiles([...updated])
        setTimeout(() => {
            setLoadingFilesTable(false)
        }, 500)
    }

    useEffect(() => {
        fetchFiles()
    }, [])

    const resetCheckBoxes = () => {
        const updated = files.map((f: IFile) => {
            f.disabled = false
            f.selected = false
            return f
        })
        setFiles([...updated])
    }

    const handleFileSelectCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.checked === true) {
            const file = files.find(f => f.file_id === +e.target.id)
            if (file !== undefined) setSelectedFile(file)
            const updated = files.map((f) => {
                if (f.file_id === +e.target.id) {
                    f.disabled = false
                    f.selected = true
                } else {
                    f.disabled = true
                    f.selected = false
                }
                return f
            })
            setFiles([...updated])

        } else {
            setSelectedFile(null)
            const updated = files.map((f) => {
                f.disabled = false
                f.selected = false
                return f
            })
            setFiles([...updated])
        }
    }

    return (
        <Row className="mb-auto">
            <Col lg={7} className="mx-auto mt-5 mb-3">
                <h3 className='quango'>Files</h3>
            </Col>
            <Col lg={7} className="mx-auto mt-5 mb-5">
                {loadingFilesTable ?
                    <Spinner loading={loadingFilesTable} />
                    :
                    <FileManagementTable handleFileSelectCheckbox={handleFileSelectCheckbox} files={files} />
                }
            </Col>
            <Col lg={7} className="mx-auto mb-5">
                <Tabs handleTabSelect={handleTabSelect} activeKey={activeKey} tabs={['Upload', 'Download', 'Rename', 'Delete']} />
                <Card id='card' className="tabs-card">
                    <Card.Body>
                        {activeKey === "Upload" &&
                            <FileUpload fetchFiles={fetchFiles} handleToast={props.handleToast} />
                        }
                        {activeKey === "Download" &&

                            <FileDownload resetCheckBoxes={resetCheckBoxes} selectedFile={selectedFile} setSelectedFile={setSelectedFile} handleToast={props.handleToast} />

                        }
                        {activeKey === "Rename" &&
                            <FileRename selectedFile={selectedFile} handleToast={props.handleToast} fetchFiles={fetchFiles} />
                        }
                        {activeKey === "Delete" &&
                            <FileDelete selectedFile={selectedFile} handleToast={props.handleToast} fetchFiles={fetchFiles} />
                        }
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={7} className="mx-auto mt-5 mb-5">
                <Spinner loading={loadingIframe} />
                <iframe onLoadStart={() => setLoadingIframe(true)} onLoad={() => setLoadingIframe(false)}
                    style={{ width: '100%', height: '900px', visibility: loadingIframe ? 'hidden' : 'visible' }}
                    src="https://docs.google.com/document/d/1V8smkDEuv_3EZvPRvxdswjN3paDaokvP/edit?usp=sharing&ouid=103677032040719249278&rtpof=true&sd=true">
                </iframe>
            </Col>
        </Row >
    );
}

export default FileManagement;